.text-center {
  text-align: center;
}
.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .login-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      cursor: pointer;
      font-weight: bold;
      font-size: 26px;
    }
  }
  .login-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    .login-content {
      display: flex;
      flex-direction: row;
      // justify-content: flex-end;
      justify-content: space-between;
      // text-align: center;
      // justify-content: center;
      // justify-items: center;
      // align-items: center;
      .form-container {
        border-radius: 5px;
        background: white;
      }
      h2 {
        font-size: 20px;
        // font-weight: 600;
        // text-align: center;
        margin-bottom: 20px;
        // font-size: 32px;
      }
      .selectItem {
        display: inline-block;
        padding: 3px 10px;
        font-size: 16px;
        background-color: rgba(153, 153, 153, .8);
        margin-right:3px ;
        margin-bottom: 3px;
        color: white;
      }
      .selectItem-hover {
        display: inline-block;
        padding: 3px 10px;
        font-size: 16px;
        margin-right:3px ;
        margin-bottom: 3px;
        color: white;
        background-color: rgb(22, 155, 213);
      }
      .login-form {
        background-color: rgb(242, 242, 242);
        padding: 20px;
        overflow: hidden;
        .forget-span {
          color:blue;
          cursor:pointer;
        }
        .code-box {
          height: 100%;
          margin-left: 1px;
          background: rgba(222, 222, 222, 0.5);;
          .code-img {
            cursor: pointer;
            vertical-align: middle;
          }
        }
      }
      .tip-box {
        font-size: 16px;
        text-align: center;
        span:last-child {
          cursor: pointer;
        }
      }
      .login-form-button {
        width: 100%;
        height: 40px;
        background-color: rgb(153, 153, 153);
        border: none;
        border-radius: 70px;
        font-size: 19px;
        margin-top: 20px;
      }
      .login-form-button:hover {
        width: 100%;
        height: 40px;
        background-color: rgb(22, 155, 213);
        border: none;
        border-radius: 70px;
        font-size: 19px;
        margin-top: 20px;
      }
      // Button {
      //   width: 100%;
      //   height: 40px;
      //   background-color: #1890ff;
      //   border-color: #1890ff;
      //   font-size: 24px;
      //   margin-top: 20px;
      // }
    }
    .login-foot {
      text-align: center;
    }
  }
}
@media screen and (max-width: 500px) {
  .login {
    .login-header {
      height: 100px;
      img {
        width: 70%;
        margin-left: 10px;
      }
      span {
        margin-right: 10px;
      }
    }
    .login-container {
      .login-content {
        .img-large {
          display: none;
        }
        .form-container {
          flex: 1;
          padding: 20px 40px 40px 40px;
        }
      }
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 1366px) {
  .login {
    min-width: 1066px;
    // background-image: url('./images/bg.jpg');
    background-image: url('./images/1.jpg');
    background-size: 100% 100%;
    .login-header {
      height: 80px;
      padding: 0 60px;
      background-color: rgba(255, 255, 255, 0.6);
      img {
        width: 272px;
        height: 46px;
      }
      span {
        // font-size: 18px;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .login-container {
      .login-content {
        .img-large {
          width: 500px;
          height: 380px;
          margin: 50px 0 0 100px;
        }
        .form-container {
          width: 380px;
          min-height: 340px;
          max-height: 420px;
          margin: 60px 100px 0 0;
          padding: 20px 40px 40px 40px;
          box-shadow: 0px 12px 13px 1px #c4c4c4;
        }
      }
    }
  }
}

@media screen and (min-width: 1367px) {
  .login {
    min-width: 1066px;
    // background-image: url('./images/bg.jpg');
    background-image: url('./images/1.jpg');
    background-size: 100% 100%;
    .login-header {
      height: 100px;
      padding: 0px 60px;
      background-color: rgba(255, 255, 255, 0.6);
      img {
        width: 272px;
        height: 46px;
      }
    }
    .login-container {
      .login-content {
        .img-large {
          width: 550px;
          height: 400px;
          margin: 90px 0 0 110px;
        }
        .form-container {
          width: 480px;
          height: 480px;
          margin: 70px 140px 0 0;
          padding: 20px 40px 40px 40px;
          box-shadow: 0px 12px 13px 1px #c4c4c4;
        }
      }
    }
  }
}

@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;