#root{
  width: 100%;
  height: 100%;
.srm-layout{
  width: 100%;
  height: 100%!important;
  overflow-y: hidden;
  max-height: 100%;
  div{
    aside.left-sider{
      max-width: 400px!important;
      height: 100%;
      width:100% !important;
      background-color: #001529;
      .ant-layout-sider-trigger{
        background-color: #001529;;
      }
    }
  }

  .content-layout {
    height: 100%!important;
    background-color: #f0f2f5;
    // background-color: white;
    .ant-spin-nested-loading{
      height: 100%;
      .ant-spin-container{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .site-layout-header {
          display: flex;
          height: 50px;
          background:white;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0,0,0,.1);
          .site-layout-head-left{
            display: flex;
            align-items: center;
            color: black;
            .site-layout-head-left-collapsed{
              display: inline-block;
              font-size: 20px;
              width: 64px;
              height: 50px;
              line-height: 50px;
              text-align: center;
              font-weight: normal;
            }
            // .ant-breadcrumb-link{
            //   color: black;
            // }
            // .ant-breadcrumb-separator{
            //   color: black;
            // }
          }
          .site-layout-header-right {
            margin: 0 30px 0px 28px;
            display: flex;
            line-height: 100%;
            align-items: center;
            // color: white;
            //font-size: 18px;
            justify-content: flex-end;
            .avatar{
              width: 41px;
              height: 40px;

            }
            .site-layout-header-content-user-info{
              font-size: 15px;
              margin: 0 10px;
              color: black;
            }

            .message-text{
              font-size: 15px;
              margin-left: 20px;
            }
            // .language{
            //   display: inline-block;
            //   width: 38px;
            //   font-size: 15px;
            //   cursor: pointer;
            //   margin-right: -10px;
            //   margin-left: 20px;
            //   color: black;
            // }
            .translate{
              display: inline-block;
              width: 38px;
              font-size: 20px;
              margin-right: -10px;
              margin-left: 20px;
              color: rgba(11, 11, 11,.8);
            }
            .logout{
              margin-left: 15px;
              font-size: 15px;
              .logout-icon{
                width: 21px;
                height: 25px;
                font-size: 20px;
              }
              .logout-text{
                margin-left: 5px;
              }
            }
          }
        }
        .ant-breadcrumb-link{
          color: black;
        }
        .ant-breadcrumb-separator{
          color: black;
        }
        .container {
          flex-grow: 1;
          //margin: 17px 18px 0 2px;
          // margin: 3px 3px 8px 3px;
          margin: 6px 6px 6px 6px;
          position: relative;

          .pagination-container{
            position: relative;
            right: 0;
            // padding-top: 10px;
            padding: 10px 12px 0 0;
            float: right;
            // bottom: 0;
          }
          .ant-table-tbody .ant-table-row .ant-table-cell div{
            // overflow: hidden;
            white-space:nowrap;
            text-overflow: ellipsis;
          }
        }
        .footer.ant-layout-footer{
          padding: 0;
          line-height: 40px;
          height: 40px!important;
          margin: 6px 0 0 16px;
        }
        .other-container{
          flex-grow: 1;
          margin: 6px 6px 6px 6px;
          position: relative;
          .top{
            background:white;
            padding: 15px 10px 0px 17px;
            .top-top1{
              border-bottom:1px solid #ccc;
              margin-bottom: 10px;
              img {
                width: 74px;
                height: 87px;
                text-align: center;
                // display: inline-block;
                margin: 15px 20px 10px 0;
              }
              .box{
                display: flex;
                p{
                  height: 44px;
                  width: 100px;
                  // padding: 5px 5px;
                  border: 1px solid #ccc;
                  text-align: center;
                  margin-right: 10px;
                  span{
                    display: block;
                  }
                }
              }
            }
            .table-right{
              margin-left: 20px;
              display: flex;
              .table-right-title{
                padding-right: 20px;
                margin-right: 20px;
                border-right: 1px solid #ccc;
              }
              .table-right-content .view-wrap-item-content{
                margin-left: 20px;
              }
            }
          }
          .table-p{
            font-weight: 700;
            font-style: normal;
            font-size: 16px;
          }
          .col-span{
            font-size: 14px;
            display: inline-block;
            line-height: 32px;
            margin-right: 5px;
          }
          .inventorySearch{
            display: flex;
          }
          .ant-col.ant-col-8{
            padding-left: 9px; 
            padding-right: 9px
          }
          
        }
      }
    }
  }
}
}

.ant-card-body {
  overflow: auto !important;
}
.archiveCard{
  .ant-card-body{
    display: flex;
    p{
      margin:5px 20px 10px 7px
    }
  }
}
@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;