
.buts-container {
          //  background-color: white;
           padding: 10px 0 0 12px;
           margin-bottom: 3px;
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           //box-shadow: 2px 12px 13px 2px #d7d7d7;

           .buts-container-button {
             margin: 0px 10px 10px 0px;
             border-radius: 4px;
             background-color: #1890ff;
             border-color: #1890ff;
             display: flex;
             flex-direction: row;
             justify-content: center;
             align-items: center;
             color: white;
             span {
               color: white;
               font-size: 14px;
             }

             .buts-container-img {
               margin-right: 10px;
             }
           }
           .buts-container-button-disabled {
            margin: 0px 10px 10px 0px;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .buts-container-img {
              margin-right: 10px;
            }
          }
         }


@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;