/*拖拽上传样式*/
.dragger-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dragger-upload-container .dragger-upload > span {
  display: inline-block;
  width: 460px;
}
.dragger-upload-container .dragger-upload > span img {
  margin: 20px 0;
}
.dragger-upload-container .dragger-upload > span .title-info {
  font-size: 18px;
  color: black;
  margin-bottom: 8px;
}
.dragger-upload-container .dragger-upload > span .sub-title-info {
  font-size: 14px;
  color: #989898;
}
.dragger-upload-container .ant-upload-list {
  max-height: 60px;
  overflow-y: auto;
}
.dragger-upload-container .ant-upload-list .ant-upload-list-item .ant-upload-text-icon .anticon-paper-clip {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: url('../images/link-icon.svg') 100% no-repeat;
}
.dragger-upload-container .ant-upload-list .ant-upload-list-item .ant-upload-text-icon .anticon-paper-clip svg {
  display: none;
}
.dragger-upload-container .ant-upload-list .ant-upload-list-item .ant-upload-list-item-card-actions .anticon-delete {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: url('../images/delete-icon.svg') 100% no-repeat;
}
.dragger-upload-container .ant-upload-list .ant-upload-list-item .ant-upload-list-item-card-actions .anticon-delete svg {
  display: none;
}
.dragger-upload-container .ant-upload-list .ant-upload-list-item.ant-upload-list-item-error .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-card-actions .anticon-delete {
  display: inline-block;
  height: 16px;
  width: 16px;
  background: url('../images/delete-error-icon.svg') 100% no-repeat;
}
.dragger-upload-container .ant-upload-list .ant-upload-list-item.ant-upload-list-item-error .ant-upload-list-item-info .ant-upload-span .ant-upload-list-item-card-actions .anticon-delete svg {
  display: none;
}
.dragger-upload-container .submit-btn {
  width: 150px;
  height: 41px;
  font-size: 18px;
  border: 1px solid black;
  margin-top: 20px;
}
