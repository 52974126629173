.rules-modal {
    .ant-modal-title {
      font-size: 20px;
      font-weight: bold;
    }
    .ant-modal-body {
      .title {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
      .content-wrap {
        padding: 20px;
        .rules-title {
          color: #008000;
          font-size: 20px;
          font-weight: bold;
        }
        .rules-content {
          text-indent: 36px;
          font-size: 18px;
        }
      }
      .form-foot {
        margin-bottom: 20px;
      }
    }
  }


@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;