.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*设置提示框距离文本的宽度*/
.ant-message-custom-content{
  display: flex;
  align-items: center;
  >span{
    svg{
      margin-right: 19px;
      width: 29px;
      height: 29px;
    }
  }
  >span{
    font-size: 16px;
    color: black;
  }

}

/*整个滚动条*/
::-webkit-scrollbar {
  width: 12px;
  height: 14px;
  background-color: #fff;
}

/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: #fff;
}

/*定义滑块*/
::-webkit-scrollbar-thumb {
  // background-color:
  background-color: rgb(179, 177, 177);
  border-radius: 4px;
}

/*编辑table单元格*/
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
}

/*.editable-row:hover .editable-cell-value-wrap {
  //padding: 4px 11px;
  padding-left: 2px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  //cursor: pointer;
}*/

/*.editable-row.tr td{
  padding: 3px 8px!important;
}*/


/* 必填标识*添加 */
.redRequired:before{
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
/*列标题前面加**/
th.addStart:before{
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 16px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

th.addEditIcon:after{
  content:'';
  display: inline-block;
  background-image: url("./asserts/images/editIcon.jpg");
  background-size: 100% 100%;
  width: 18px;
  height: 20px;
  margin:0 0 -3px 5px;
}

//.ant-table-tbody tr:nth-child(2n+1) {
//  background-color: red;
//}

.ant-table-tbody tr:hover{
  filter:alpha(opacity=100);  /*支持 IE 浏览器，范围值0~100，值越大透明度越高哦*/
  -moz-opacity:1; /*支持 FireFox 浏览器，范围值0~1，*/
  opacity:1;  /*支持 Chrome, Opera, Safari 等浏览器，范围值0~1*/

}

//设置奇数行/偶数行的背景颜色
.oddRow {
  td{

  }
}
.evenRow {
  td{
    background-color: #F2F2F2;
  }
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th {
  text-align: center !important;
}
 .ant-table.ant-table-small .ant-table-tbody > tr > td,
 .ant-table.ant-table-small tfoot > tr > th,
 .ant-table.ant-table-small tfoot > tr > td {
  padding: 2px 2px;
}


.notEditTable .ant-table.ant-table-small .ant-table-tbody > tr > td,
.notEditTable .ant-table.ant-table-small tfoot > tr > th,
.notEditTable .ant-table.ant-table-small tfoot > tr > td {
  padding: 7px 2px;
}

/*设置表头的高度*/
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-thead > tr > th{
  padding: 0px 2px;
  height: 35px;
}


.react-resizable {
  position: relative ;
  background-clip: padding-box;
}
.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -9px;
  cursor: col-resize;
  z-index: 1;
  border-left: white 1px solid;
}
.ant-table.ant-table-small{
  // font-size: 16px;
  text-align: center !important;
  font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-table-wrapper{
  // margin-top: 16px;
}
// .ant-modal{
//   padding-bottom: 0px;
//   margin: 0px;
//   position: fixed;
//   bottom: 5px !important;
//   right: 20px;
// }
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  width: auto;
  max-width: calc(100vw - 32px);
  padding-bottom: 0px;
  margin: 0px;
  position: fixed;
  bottom: 5px !important;
  right: 20px;
}

@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;