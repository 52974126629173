
.ant-modal-body {
  padding: 20px 20px 20px 30px;
  border-radius: 0 0 4px 4px;
  height: 430px;
  overflow-y: auto;
  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-btns {
      float: none;
      text-align: center;
    }
  }
}
.ant-form-item {
  margin-bottom: 15px;
}
.form-foot {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  Button {
    margin-right: 20px;
  }
}
.form-top {
  width: 100%;
  margin-bottom: 5px;
  Button {
    margin-right: 20px;
  }
}
.ant-modal-header {
  padding: 8px 24px;
  border-radius: 4px 4px 0 0;
}
.ant-modal-content {
  .ant-modal-header {
    background: rgb(228, 228, 228);
    border: 1px solid rgb(228, 228, 228);
    div {
      color: #333333;
      font-weight: 600;
    }
  }
  .ant-modal-body{
    background-color:rgb(242, 242, 242)
  }
  .ant-modal-close {
    color: #333333;
    .ant-modal-close-x {
      color: #333333;
      height: 40px;
      line-height: 40px;
    }
  }
}
/*Modal头部样式*/
.ant-modal {
  /* .custom-modal-title{
    font-size: 20px;
    text-align: center;
    margin: 9px 0 16px 0;
    //margin: 0px 0 10px 0;
    font-weight: bold;
  }*/
  /*  .ant-modal-content{
    border-radius: 10px;
    >Button{
      border-radius: 0 10px 0 0;
      background: red;
      height: 41px;
      .ant-modal-close-x{
        width: 98px;
        height: 41px;
        line-height: 41px;
        display: flex;
        justify-content:flex-end;
        align-items: center;
        padding-right: 28px;
        .anticon svg{
          &:hover .anticon svg{
            color:#fff;
          }
          width: 25px;
          height: 25px;
          text-align: right;
          color:#fff!important;
        }
      }
    }
  }*/
  /*.ant-modal-header{
    padding: 20px 0;
    background:#c9c9c9 ;
    border-radius: 10px 10px 0 0;
  }*/
  .ant-modal-footer {
    display: none;
  }
}

/*查看modal的样式*/
.view-modal {
  .detail__container {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
  }
  .detail__flex--row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .detail__row {
    display: flex;
    flex-direction: row;
    width: 50%;
    font-size: 14px;
  }
  .detail__title {
    font-weight: bold;
    margin-right: 15px;
  }
  .detail__content {
    flex-grow: 1;
    display: inline-block;
    min-height: 30px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .handle__record {
    width: 100%;
    font-size: 14px;
    :first-child {
      font-weight: bold;
    }
  }
  .handle__record--link {
    cursor: pointer;
    color: #1890ff;
    margin-left: 8px;
  }
  .view-wrap {
    //border: 1px solid black;
    //padding: 8px 20px;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    max-height: 500px;
    //height: 400px;
    // overflow-y: auto;
    p {
      display: flex;
      flex-direction: row;
      // width: 50%;
      font-size: 14px;
      margin: 0;
      :first-child {
        font-weight: bold;
        margin-right: 15px;
        flex: 1;
        display: inline-block;
      }
      :last-child {
        flex: 2;
        display: inline-block;
        min-height: 30px;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
    .confirm-date {
      margin-top: 15px;
      width: 100%;
      font-size: 14px;
      :first-child {
        font-weight: bold;
      }
    }
  }
  .view-wrap-noy {
    //border: 1px solid black;
    //padding: 8px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 500px;
    padding-bottom: 10px;
    //height: 400px;
    // overflow-y: auto;
    p {
      display: flex;
      flex-direction: row;
      width: 50%;
      font-size: 14px;
      margin: 0;
      :first-child {
        font-weight: bold;
        margin-right: 15px;
      }
      :last-child {
        flex-grow: 1;
        display: inline-block;
        min-height: 30px;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
    .confirm-date {
      margin-top: 15px;
      width: 100%;
      font-size: 14px;
      :first-child {
        font-weight: bold;
      }
    }
  }
}

/*添加表单*/

.add-form {
  //display: flex;
  //flex-direction: row;
  //align-items:flex-start;
  //flex-wrap: wrap;
  .view-wrap {
    .ant-form-item {
      width: 90%;
      text-align: center;
      //margin-bottom: 0;
      height: 30px;
      float: left;
      .ant-form-item-control {
        margin-bottom: 0px;
      }
    }
  }

  .form-foot {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    Button {
      margin-right: 20px;
    }
  }
  .form-top {
    width: 100%;
    margin-bottom: 5px;
    Button {
      margin-right: 20px;
    }
  }
}

.add-form-list {
  border: 1px dashed rgb(166, 165, 165);
  margin-top: 5px;
  padding: 5px;
  //display: flex;
  //flex-direction: row;
  //align-items:flex-start;
  //flex-wrap: wrap;
  .view-wrap {
    .ant-form-item {
      width: 70%;
      text-align: center;
      //margin-bottom: 0;
      height: 30px;
      float: left;
      .ant-form-item-control {
        margin-bottom: 0px;
      }
    }
  }

  .form-foot {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    Button {
      margin-right: 20px;
    }
  }
  .form-top {
    width: 100%;
    margin-bottom: 5px;
    Button {
      margin-right: 20px;
    }
  }
}
@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;