.div-box {
  position: relative;
  width: 60%;
  left:20%;
  top:20%;
  .ant-card{
    padding: 0 10px;
    border: none;
    border: 1px solid rgba(121, 121, 121, 1) !important;
    .ant-card-head {
      border: none;
      border-bottom: 1px solid rgba(121, 121, 121, 1) !important;
    }
  }
  .form-box {
    padding-left:70px;
  }
}
@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;