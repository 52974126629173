.left-sider {
  width: 100%!important;
  .nav-left {
    .nav-left-header {
      display: flex;
      // height: 60px;
      height: 80px;
      align-items: center;
      line-height: 60px;
      font-weight: bold;
      font-size: 20px;
      .nav-left-header-logo {
        // width: 46px;
        // height: 50px;
        width:35px;
        height: 35px;
        // margin-left: 8px;
        // margin: 15px 11px 5px 11px;
      }

      .logo-text {
        display: inline-block;
        width: 100%;
        text-align: center;
        letter-spacing:2px;
        font-size: 20px;
        color: white;
      }
      // .avatar{
      //   width: 36px;
      //   height: 36px;

      // }
      /*span {
        display: block;
        width: 100%;
        word-break:keep-all; !* 不换行 *!
        white-space:nowrap; !* 不换行 *!
        overflow:hidden; !* 内容超出宽度时隐藏超出部分的内容 *!
      }*/

    }

    .nav-left-menu {
      height: calc(100vh - 80px);
      // height: calc(100vh);
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 15px;
      .ant-menu-submenu.ant-menu-submenu-inline {
        // min-height: 45px;
        // line-height: 45px;
        font-size: 15px;
      }

      .ant-menu-vertical {
        .ant-menu-submenu.ant-menu-submenu-vertical {
          .ant-menu-submenu-title {
            //margin: 0;
          }
        }
      }
      .ant-menu-item,.ant-menu-item-only-child{
        //padding-left: 20px !important;
        min-height: 45px;
        line-height: 45px;
      }

      .ant-menu-submenu.ant-menu-submenu-inline {
        .ant-menu-submenu-title {
          min-height: 45px;
          line-height: 45px;
          //height: 50px;
          //line-height: 50px;
          //padding-left: 65px !important;

          // &:hover {
          //   a {
          //     color: #ff4b5a;
          //   }
          // }

          /*i.ant-menu-submenu-arrow {
            display: none;
          }*/
        }

        .ant-menu-sub {
          .ant-menu-item {//ant-menu-submenu-title
            //padding-left: 65px!important;

            // &:hover {
            //   background: #f7d3d5;
            // }

            a {
              font-size: 15px;
              // color: #707070;

              // &:hover {
              //   color: #ff4b5a;
              // }
            }

          }

          .ant-menu-item.ant-menu-item-selected {
            // a {
            //   //font-size: 18px;
            //   color: #ff4b5a;
            // }
          }
        }
      }
    }
  }

  // .ant-layout-sider-trigger {
  //   //height: 66px;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;

  //   .anticon-left {
  //     width: 24px;
  //     height: 24px;
  //     background-size: 100% 100%;
  //     background-image: url("../../asserts/images/unexpand.svg");
  //   }

  //   .anticon-right {
  //     width: 24px;
  //     height: 24px;
  //     background-size: 100% 100%;
  //     background-image: url("../../asserts/images/expand.svg");
  //   }

  //   svg {
  //     display: none;
  //   }
  // }
}



@btn-primary-bg: #1890ff;@btn-border-radius-base: 4px;@message-notice-content-padding: 16px 36px 16px 25px;@modal-header-bg: #E4E4E4;@modal-header-close-size: 41px;@modal-header-padding: 20px;@modal-close-color: #fff;@modal-body-padding: 20px;@breadcrumb-font-size: 15px;@breadcrumb-icon-font-size: 15px;@breadcrumb-base-color: #fff;@breadcrumb-last-item-color: #fff;@breadcrumb-separator-color: #fff;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;@menu-bg: #f5f5f5;@menu-item-active-bg: #f7d3d5;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@menu-highlight-color: #ff4b54;@menu-popup-bg: #f5f5f5;@table-row-hover-bg: aaa;@table-selected-row-hover-bg: bbb;